<script>
import InspeccionForm from "./InspeccionForm";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
const Swal = require("sweetalert2");

export default {
  computed: {
    itemsForm() {
      return [
        {
          name: "documentacion",
          title: "Documentación",
        },
        {
          name: "OrdenYAseo",
          title: "Orden y aseo",
        },
        {
          name: "carroceria",
          title: "Carrocería",
        },
        {
          name: "cabina",
          title: "Cabina",
        },
        {
          name: "motor",
          title: "Motor",
        },
        {
          name: "herramientas",
          title: "Herramientas",
        },
        {
          name: "kitAmbiental",
          title: "Kit ambiental",
        },
      ];
    },
  },
  async mounted() {
    this.$store.dispatch("initInspeccionVehiculo");
    if (this.idInspeccion) {
      this.updateData = await this.getInspeccionById(this.idInspeccion);
    }
  },
  data() {
    return {
      idInspeccion: this.$route.params.idinspeccion || null,
      readOnly: this.$route.path.includes("detalles-inspeccion"),
      updateData: null,
    };
  },
  methods: {
    async getInspeccionById(id) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RevisionPeriodicaVehiculo/Alone/" + id,
        });
        console.log("-----------inspeccion data----------", res);
        if (res.id) {
          return res;
        }
      } catch (error) {
        console.log("err", error);
        Swal.fire("Error!", "Error al obtener datos", "error");
        return null;
      }
    },
    updateIdInspeccion(id) {
      this.idInspeccion = id;
      this.$router.push({
        name: "ContentFormInspeccionNew",
        params: { ...this.$route.params, idinspeccion: id },
      });
    },
  },
  filters: {
    formatDateTime(dateString) {
      return dateString
        ? moment(dateString).format("MMM D, YYYY h:mm A")
        : "...";
    },
  },

  components: {
    InspeccionForm,
  },
};
</script>

<template>
  <HXContentCard :toBack="{ name: 'ListadoInspecciones' }">
    <template v-slot:title>
      <span>
        {{
          !idInspeccion && !readOnly
            ? "Nueva"
            : idInspeccion && !readOnly
            ? "Actualizar"
            : "Visualizar"
        }}
        Inspección:
      </span>
      <br />
      <small class="text-white-50">
        Vehículo: <b>{{ $route.params.placa.toUpperCase() }}</b>
      </small>
    </template>
    <section class="px-4 pt-3" v-for="item in itemsForm" :key="item.name">
      <h3 class="font-weight-bold">{{ item.title }}</h3>
      <p v-show="!readOnly">
        <span class="text-danger" aria-hidden="true">*</span>
        <strong
          >Luego de diligenciar este formulario, asegúrese de guardar los
          cambios.</strong
        >
      </p>
      <p v-show="readOnly">
        <span class="text-danger" aria-hidden="true">*</span>
        <strong>Solo lectura, no puede realizar cambios.</strong>
      </p>
      <InspeccionForm
        :readOnly="readOnly"
        :dataInspeccion="updateData"
        :idInspeccion="idInspeccion"
        storeStateComponent="inspeccionVehiculo"
        :currencyModel="item.name"
        @update:idInspeccion="updateIdInspeccion"
      />
      <hr />
    </section>
  </HXContentCard>
</template>
