<script>
const Swal = require("sweetalert2");
export default {
  props: {
    dataInspeccion: {
      type: Object,
      required: false,
    },
    idInspeccion: {
      type: String,
      required: false,
    },
    readOnly: {
      type: Boolean,
      required: false,
    },
    storeStateComponent: {
      type: String,
      required: true,
    },
    currencyModel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dataPatch: {},
      dataPost: this.initializeDataPost(),
    };
  },
  async mounted() {
    await this.initializeData();
  },
  watch: {
    dataInspeccion: {
      // immediate: true,
      handler(newVal) {
        if (newVal) {
          this.updateFormValues();
        }
      },
    },
  },
  methods: {
    initializeDataPost() {
      return {
        id: null,
        vehiculoId: this.$route.params.vehiculoid,
        fechaRegistro: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DDTHH:mm"),
        fechaFinalizacion: null,
        kilometraje: 0,
        tipoInspeccion: null,
        tarjetaPropiedad: null,
        seguroObligatorio: null,
        revisionTecnomecanica: null,
        polizaContractual: null,
        observacionesDocumento: null,
        aseoGeneral: null,
        accesoPisosSinObstaculos: null,
        almacenamientoHerramienta: null,
        almacenamientoRepuestos: null,
        cabinaOrdenadaSinObjetosSuelo: null,
        observacionesOrdenAseo: null,
        llantaRepuesto: null,
        estadoLlantas: null,
        rines: null,
        bomperTrasero: null,
        bomperDelantero: null,
        stop: null,
        placa: null,
        guardaPolvos: null,
        emblemas: null,
        manijas: null,
        barraAntivuelco: null,
        cubrePlaton: null,
        vidrios: null,
        espejos: null,
        lameVidrio: null,
        cocuyos: null,
        plumillas: null,
        mionas: null,
        antena: null,
        direccionales: null,
        luces: null,
        ganchosRemolque: null,
        llavero: null,
        observacionesCarroceria: null,
        sillaConductor: null,
        sillaCopiloto: null,
        sillaPasajeros: null,
        forros: null,
        volante: null,
        tableroInstrumentos: null,
        espejoRetrovisor: null,
        reloj: null,
        funcionPito: null,
        tapetes: null,
        manijaFrenoDeMano: null,
        botonesAc: null,
        radio: null,
        controlRadio: null,
        parlantes: null,
        elevavidrios: null,
        switchEspejos: null,
        switchLuces: null,
        viseras: null,
        cinturonesSeguridad: null,
        manijasInternas: null,
        encendedor: null,
        cenicero: null,
        observacionesCabina: null,
        bateria: null,
        filtroTrampa: null,
        posicionCorreas: null,
        bayoneta: null,
        tapas: null,
        conexionesCables: null,
        niveles: null,
        observacionesMotor: null,
        gato: null,
        palancaGato: null,
        conos: null,
        hombreSolo: null,
        alicate: null,
        llavesBocaFija: null,
        cablesInicio: null,
        botiquin: null,
        extintor: null,
        llaveAlemana: null,
        llavePerno: null,
        tacosPlasticos: null,
        calibradorPresion: null,
        lamparaLinterna: null,
        destornilladorMixto: null,
        llavesBristol: null,
        manila12m: null,
        otras: null,
        observacionesHerramientas: null,
        palaAntichispasEscobilla: null,
        telaOleofilicaPanosAbsorbentes: null,
        bolsasRojas: null,
        cintaDeSeguridad: null,
        guantesDeNitrilo: null,
        proteccionRespiratoria: null,
        observacionesElementosSeguridad: null,
      };
    },
    async initializeData() {
      if (!this.idInspeccion && !this.dataInspeccion) {
        this.modelStore?.forEach((item) => {
          item.value = item.name === "kilometraje" ? 0 : null;
        });
      }
    },
    updateFormValues() {
      this.modelStore.forEach((item) => {
        const value = this.dataInspeccion[item.name];

        if (value != null && value !== 0) {
          item.value =
            typeof value === "string" && value.toLowerCase() === "noaplica"
              ? "noAplica"
              : typeof value === "string"
              ? value.toLowerCase()
              : value;
        } else {
          item.value = item.name === "kilometraje" ? 0 : null;
        }
      });
    },
    async onSubmitComponent() {
      let model = this.modelStore;
      let dataToSend = this.idInspeccion ? this.dataPatch : this.dataPost;

      model.forEach((item) => {
        if (item.value) {
          dataToSend[item.name] =
            item.type === "number" ? parseInt(item.value) : item.value;
        }
      });

      this.idInspeccion
        ? await this.PatchData(this.idInspeccion, dataToSend)
        : await this.PostData(dataToSend);
    },

    async handleResponse(res) {
      if (res.status === 409) {
        Swal.fire({
          title: res.title,
          text: res.message,
          icon: "info",
          confirmButtonText: "Aceptar",
        });
        return false;
      }

      if (res) {
        Swal.fire({
          title: "!Registro guardado!",
          text: "Se han guardado los cambios.",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        return true;
      }

      return false;
    },
    async sendData(method, id = null, data) {
      try {
        data.usuarioId = this.$store.getters.userLoggedIn.id;

        const path = id
          ? `RevisionPeriodicaVehiculo/${id}`
          : `RevisionPeriodicaVehiculo/`;
        const res = await this.$store.dispatch(method, { path, data });

        const success = await this.handleResponse(res);

        if (success && method === "post") {
          this.$emit("update:idInspeccion", res);
        }
      } catch (error) {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    async PostData(data) {
      await this.sendData("post", null, data);
    },

    async PatchData(id, data) {
      await this.sendData("patch", id, data);
    },
  },
  computed: {
    modelStore() {
      return this.$store.state[this.storeStateComponent][this.currencyModel];
    },
  },
};
</script>

<template>
  <b-form class="mb-4" @submit.prevent="onSubmitComponent()">
    <div class="row">
      <b-form-group
        class="col-12 col-lg-4"
        v-for="(item, index) in modelStore"
        :key="item.name"
        :label="item.title"
        :label-for="`${currencyModel}-${item.name}`"
      >
        <b-form-input
          v-if="
            item.type === 'text' ||
            item.type === 'number' ||
            item.type === 'date' ||
            item.type === 'time'
          "
          v-model="item.value"
          :id="`${currencyModel}-${item.name}`"
          :type="item.type"
          :placeholder="item.placeholder"
          :readonly="readOnly"
        ></b-form-input>

        <b-form-select
          v-if="item.type === 'select'"
          v-model="item.value"
          :id="`${currencyModel}-${item.name}`"
          :options="item.options"
          :placeholder="item.placeholder"
          class="custom-disabled"
          :disabled="readOnly"
          :required="index === 0"
        ></b-form-select>

        <b-form-textarea
          v-if="item.type === 'textarea'"
          :id="`${currencyModel}-${item.name}`"
          v-model="item.value"
          rows="3"
          :placeholder="item.placeholder"
          :readonly="readOnly"
          :style="{ resize: readOnly && 'none' }"
        >
        </b-form-textarea>
      </b-form-group>
    </div>
    <div class="d-flex flex-row-reverse mb-3" v-if="!readOnly">
      <vs-button>
        {{
          idInspeccion && dataInspeccion
            ? "Actualizar y continuar"
            : "Guardar y continuar"
        }}
      </vs-button>
    </div>
  </b-form>
</template>

<style scoped></style>
